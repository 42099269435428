import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SocialType } from '../../../routes/holding-page/holding-page.component';

@Component({
  selector: 'app-footer',
  imports: [RouterLink],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  SocialType = SocialType;
  // Navigation links data for easy mapping
  protected navigationLinks = [
    {
      column: 1,
      links: [
        { title: 'Become a sponsor', route: 'sponsor' },
        { title: 'Our sponsors', route: 'our-sponsors' },
      ],
    },
    {
      column: 2,
      links: [
        { title: 'Awards', route: 'awards' },
        { title: 'Gallery', route: 'gallery' },
      ],
    },
    {
      column: 3,
      links: [
        { title: 'Blog', route: 'blog' },
        { title: 'Contact us', route: 'contact-us' },
      ],
    },
  ];

  goToSocial(type: SocialType) {
    switch (type) {
      case SocialType.Instagram: {
        window.open('https://www.instagram.com/developherawards', '_blank');
        break;
      }
      case SocialType.LinkedIn: {
        window.open(
          'https://www.linkedin.com/company/developher-awards',
          '_blank'
        );
        break;
      }
      case SocialType.X: {
        window.open('https://www.x.com/syncdevelopher');
        break;
      }
    }
  }
}
