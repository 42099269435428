@if(!showHoldingPage){

<app-header></app-header>

<router-outlet></router-outlet>

<app-footer></app-footer>

} @else {
<app-holding-page></app-holding-page>
}
