<footer class="px-5 py-5 bg-dark flex flex-col items-center justify-center">
  <div class="d-md-flex items-center text-center items-md-start pb-5">
    <!-- Logo section -->
    <div class="d-md-flex flex-column mx-auto">
      <img width="200" src="./assets/developher-logo-master 1.png" />
    </div>

    <!-- Navigation links section -->
    <nav class="d-none d-md-inline-flex items-center pt-2 w-50">
      <div class="d-flex flex-fill">
        @for(column of navigationLinks; track column.column){
        <div class="d-flex flex-column flex-fill">
          @for(link of column.links; track link.title){
          <a
            [routerLink]="link.route"
            class="d-flex items-center text-white text-medium"
            [class.mt-4]="$index !== 0"
          >
            <span class="mt-0 font-weight-bold">
              {{ link.title }} <i class="fa fa-sm fa-arrow-right"></i>
            </span>
          </a>
          }
        </div>
        }
      </div>
    </nav>

    <div class="text-white text-center mx-auto">
      <div class="">
        <i
          class="fa-brands fa-x-twitter me-3 pointer scale"
          (click)="goToSocial(SocialType.X)"
        ></i>
        <i
          class="fa-brands fa-instagram me-3 pointer scale"
          (click)="goToSocial(SocialType.Instagram)"
        ></i>
        <i
          class="fa-brands fa-linkedin me-3 pointer scale"
          (click)="goToSocial(SocialType.LinkedIn)"
        ></i>
      </div>
    </div>
  </div>
</footer>
